import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { geoCentroid } from "d3-geo";
import { scaleQuantile } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";

const allStates = [
  { "id": "AL", "val": "01" },
  { "id": "AK", "val": "02" },
  { "id": "AS", "val": "60" },
  { "id": "AZ", "val": "04" },
  { "id": "AR", "val": "05" },
  { "id": "CA", "val": "06" },
  { "id": "CO", "val": "08" },
  { "id": "CT", "val": "09" },
  { "id": "DE", "val": "10" },
  { "id": "DC", "val": "11" },
  { "id": "FL", "val": "12" },
  { "id": "FM", "val": "64" },
  { "id": "GA", "val": "13" },
  { "id": "GU", "val": "66" },
  { "id": "HI", "val": "15" },
  { "id": "ID", "val": "16" },
  { "id": "IL", "val": "17" },
  { "id": "IN", "val": "18" },
  { "id": "IA", "val": "19" },
  { "id": "KS", "val": "20" },
  { "id": "KY", "val": "21" },
  { "id": "LA", "val": "22" },
  { "id": "ME", "val": "23" },
  { "id": "MH", "val": "68" },
  { "id": "MD", "val": "24" },
  { "id": "MA", "val": "25" },
  { "id": "MI", "val": "26" },
  { "id": "MN", "val": "27" },
  { "id": "MS", "val": "28" },
  { "id": "MO", "val": "29" },
  { "id": "MT", "val": "30" },
  { "id": "NE", "val": "31" },
  { "id": "NV", "val": "32" },
  { "id": "NH", "val": "33" },
  { "id": "NJ", "val": "34" },
  { "id": "NM", "val": "35" },
  { "id": "NY", "val": "36" },
  { "id": "NC", "val": "37" },
  { "id": "ND", "val": "38" },
  { "id": "MP", "val": "69" },
  { "id": "OH", "val": "39" },
  { "id": "OK", "val": "40" },
  { "id": "OR", "val": "41" },
  { "id": "PW", "val": "70" },
  { "id": "PA", "val": "42" },
  { "id": "PR", "val": "72" },
  { "id": "RI", "val": "44" },
  { "id": "SC", "val": "45" },
  { "id": "SD", "val": "46" },
  { "id": "TN", "val": "47" },
  { "id": "TX", "val": "48" },
  { "id": "UM", "val": "74" },
  { "id": "UT", "val": "49" },
  { "id": "VT", "val": "50" },
  { "id": "VA", "val": "51" },
  { "id": "VI", "val": "78" },
  { "id": "WA", "val": "53" },
  { "id": "WV", "val": "54" },
  { "id": "WI", "val": "55" },
  { "id": "WY", "val": "56" }
]

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
  FL: [1, 1],
};

const rounded = num => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + "Bn";
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else {
    return Math.round(num / 100) / 10 + "K";
  }
};

const MapChart = ({ setTooltipContent, casesData }) => {

  const colorScale = scaleQuantile()
    .domain( Object.values(casesData["by_state"]).map(d => d))
    .range([
      // red and orange
      // "#ffedea",
      // "#ffcec5",
      // "#ffad9f",
      // "#ff8a75",
      // "#ff5533",
      // "#e2492d",
      // "#be3d26",
      // "#9a311f",
      // "#782618"

      // grey and white (greyer on top)
      // https://www.december.com/html/spec/color0.html

      "#F0F0F0",
      "#E0E0E0",
      "#D3D3D3",
      "#CDCDCD",
      "#BEBEBE",
      "#B3B3B3",
      "#A8A8A8",
      "#9C9C9C",
    ]);
  
  const genFill = (id) => {
    const cur = allStates.find(s => s.val === id);
    const count = casesData["by_state"][cur.id];
    if (count) {
      return colorScale(count);
    } else {
      return "#FAFAFA";
    }
  }

  const lookUpCount = (stateAbrev) => {
    // const { name } = geo.properties;
    const cur = allStates.find(s => s.val === stateAbrev);
    const count = casesData["by_state"][cur.id];
    return count ? count : 0;
  }
  
  return (
    <>
      <ComposableMap data-tip="" projection="geoAlbersUsa">
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}

                  onMouseEnter={() => {
                    const { name } = geo.properties;
                    const count = lookUpCount(geo.id);
                    setTooltipContent(`${name} - ${count}`);
                  }}

                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}

                  onClick={() => {
                    const cur = allStates.find(s => s.val === geo.id);
                    // this is a hacky fix
                    const url = `/search?q%5Bcase_name_cont%5D=&q%5Bcase_number_cont%5D=&q%5Bstate_cont_any%5D%5B%5D=${cur.id}&q%5Bfed_district_number_in%5D%5B%5D=&q%5Bstart_year%5D=&q%5Bdefendants_count%5D=&q%5Badult_sex_true%5D=0&q%5Bminor_sex_true%5D=0&q%5Blabor_true%5D=0&q%5Btype_of_labor_cont_any%5D%5B%5D=&q%5Btype_of_sex_cont_any%5D%5B%5D=&q%5Bdefendants_first_name_or_defendants_last_name_matches%5D=&q%5Bdefendants_gender_cont_any%5D%5B%5D=&q%5Bdefendants_race_cont_any%5D%5B%5D=&q%5Bdefendants_country_of_origin_cont_any%5D%5B%5D=&q%5Bdefendants_arrest_age%5D=&q%5Bvictim_countries_name_cont_any%5D%5B%5D=&q%5Bvictims_count%5D=&q%5Bnumber_victims_minor_gt_zero%5D=0&q%5Bnumber_victims_foreign_gt_zero%5D=0&q%5Bnumber_victims_female_gt_zero%5D=0&q%5Bnumber_victims_male_gt_zero%5D=0&defendants_statutes_any_all=any&q%5Bdefendants_s_1961_to_1968_true%5D=0&q%5Bdefendants_s_1028_true%5D=0&q%5Bdefendants_s_1324_true%5D=0&q%5Bdefendants_s_1328_true%5D=0&q%5Bdefendants_s_1351_true%5D=0&q%5Bdefendants_s_1425_true%5D=0&q%5Bdefendants_s_1512_true%5D=0&q%5Bdefendants_s_1546_true%5D=0&q%5Bdefendants_s_1581_to_1588_true%5D=0&q%5Bdefendants_s_1589_true%5D=0&q%5Bdefendants_s_1590_true%5D=0&q%5Bdefendants_s_1591_true%5D=0&q%5Bdefendants_s_1592_true%5D=0&q%5Bdefendants_s_1593_true%5D=0&q%5Bdefendants_s_1594_true%5D=0&q%5Bdefendants_s_2251_true%5D=0&q%5Bdefendants_s_2252_true%5D=0&q%5Bdefendants_s_2260_true%5D=0&q%5Bdefendants_s_2421_to_2424_true%5D=0&q%5Bdefendants_total_sentence%5D=`;
                    window.location = url;
                  }}

                  stroke="#FFF"
                  fill={genFill(geo.id)}
                  
                  style={{
                    pressed: {
                      fill: "#4D1979",
                      outline: "none"
                    }
                  }}
                />
              ))}
              {geographies.map(geo => {
                const centroid = geoCentroid(geo);
                const cur = allStates.find(s => s.val === geo.id);
                
                return (
                  <g key={geo.rsmKey + "-name"}>

                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text y="2" fontSize={14} textAnchor="middle">
                            {cur.id}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.id][0]}
                          dy={offsets[cur.id][1]}
                        >
                          <text x={4} fontSize={11} alignmentBaseline="middle">
                            {cur.id}
                          </text>
                        </Annotation>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
    </>
  );
};



const HomePageMap = () => {
  const [content, setContent] = useState("");
  const [casesData, setCasesData] = useState(null)

  useEffect(() => {
    (async () => {
      const res = await fetch('charts/cases_count.json');
      const casesData = await res.json();
      setCasesData(casesData);
    })();
  }, []);

  // If there is no statesData yet, show a loading indicator.
  if (!casesData) {
      return (
      <div>Loading...</div>
      );
  }

  return(
    <div>
      <MapChart setTooltipContent={setContent} casesData={casesData}/>
      <ReactTooltip>{content}</ReactTooltip>
    </div>
  )
};


export default HomePageMap
