/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs";
import $ from 'jquery';
import 'bootstrap';
import 'select2';
import highcharts from "commonjs-highcharts";

import "../stylesheets/select2.scss";
import "../stylesheets/select2-bootstrap.scss";
import "../stylesheets/bootstrap-slider.css";
import "../stylesheets/application.scss";

import '../src/cases';
import '../src/defendants';
import '../src/bootstrap-slider';

global.$ = $;
global.jQuery = $;

Rails.start();

$(function () {
    $('[data-toggle="popover"]').popover();
});

window.htd = {
    get_resource_id: function(level) {
        var _level = level || 1;
        var path = window.location.pathname;
        var split_url = path.split("/");
        var index = (_level * 2);
        return split_url[index];
    }
};

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
