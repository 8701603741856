import PropTypes, { string } from "prop-types"
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

const prepareTags = (tags) => {
  return tags.items.map(tag => {
    return {
      value: tag.id,
      label: tag.name,
    }
  });
};

const getOptions = async (inputValue) => {
  let url = `/tags/search_by_name?name=${inputValue}`
  return fetch(url).then(res => res.json());
};

const loadOptions = async (inputValue) => {
  let rawData = await getOptions(inputValue);
  return prepareTags(rawData);
}

const getTagsByIds = async (tagIds) => {
  let url = `/tags/search_by_ids?ids[]=${tagIds.join('&ids[]=')}`;
  return fetch(url).then(res => res.json());
}

const loadTagsByIds = async (tagIds) => {
  if (tagIds != []) {
    let rawData = await getTagsByIds(tagIds);
    return prepareTags(rawData);
  }
}
  
const TagSelector = (props) => {
  const [selectedTags, setSelectedTags] = useState();

  useEffect(() => {
    const useEffectLoadTags = async () => {
      let tags = await loadTagsByIds(props.tagIds);
      setSelectedTags(tags)
    }
    useEffectLoadTags();
  
  }, [setSelectedTags])

  const handleChange = (value) => {
    setSelectedTags(value);
  }
  
  return (
    <div className="form-group optional">
      <label className="col-sm-3 control-label select optional" htmlFor="case_tag_ids">Tags</label>
      <div className="col-sm-9">
        <AsyncSelect
          name={props.selectName}
          value={selectedTags}
          cacheOptions
          defaultOptions
          isMulti={true}
          isClearable={true}
          loadOptions={loadOptions}
          onChange={handleChange}
          />
      </div>
    </div>
  )
}

TagSelector.propTypes = {
  tagIds: PropTypes.arrayOf(PropTypes.number),
  selectName: PropTypes.string,
}

TagSelector.defaultProps = {
  selectName: 'case[tag_ids][]'
};

export default TagSelector;