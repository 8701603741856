import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

const prepareJudges = (judges) => {
  return judges.map(judge => {
    return {
      value: judge.id, 
      label: `${judge.name} - ${judge.fed_district_location}, ${judge.fed_district_number}`
    }
  });
};

const getOptions = async (inputValue) => {
  let url = `/judges/search_judges_by_name?name=${inputValue}`
  return fetch(url).then(res => res.json());  
};

const loadOptions = async (inputValue) => {
  let rawData = await getOptions(inputValue);
  return prepareJudges(rawData);
}

const getJudgeById = async (judgeId) => {
  let url = `/judges/search_judges_by_id?id=${judgeId}`;
  return fetch(url).then(res => res.json());
}

const loadJudgeById = async (judgeId) => {
  if (judgeId != null) {
    let rawData = await getJudgeById(judgeId);
    return prepareJudges(rawData)[0];
  }
}
  
const JudgeSelector = (props) => {
  const [selectedJudge, setSelectedJudge] = useState();

  useEffect(() => {
    const useEffectLoadJudge = async () => {
      let judge = await loadJudgeById(props.judgeId);
      setSelectedJudge(judge)
    }
    useEffectLoadJudge();
  }, [setSelectedJudge])

  const handleChange = (value) => {
    setSelectedJudge(value);
  }
  
  return (
    <div className="form-group optional">
      <label className="col-sm-3 control-label select optional" htmlFor="defendant_judge_id">Judge</label>
      <div className="col-sm-9">
        <AsyncSelect 
          value={selectedJudge}
          name={props.selectName}
          cacheOptions 
          defaultOptions 
          loadOptions={loadOptions}
          onChange={handleChange}
          isClearable={true}
          />
      </div>
    </div>
  )
}

JudgeSelector.propTypes = {
  judgeId: PropTypes.number,
  selectName: PropTypes.string,
}

JudgeSelector.defaultProps = {
  selectName: 'defendant[judge_id]'
};

export default JudgeSelector;