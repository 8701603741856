import PropTypes, { string } from "prop-types"
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

const prepareCases = (kases) => {
  return kases.items.map(kase => {
    return {
      value: kase.id,
      label: `${kase.case_name} - ${kase.case_number}`,
    }
  });
};

const getOptions = async (inputValue) => {
  let url = `/cases/search?q=${inputValue}`
  return fetch(url).then(res => res.json());
};

const loadOptions = async (inputValue) => {
  let rawData = await getOptions(inputValue);
  return prepareCases(rawData);
}

const getCasesByIds = async (caseIds) => {
  let url = `/cases/search_by_ids?ids[]=${caseIds.join('&ids[]=')}`;
  return fetch(url).then(res => res.json());
}

const loadForwardLinkedCasesByIds = async (forwardLinkedCaseIds) => {
  let rawData = await getCasesByIds(forwardLinkedCaseIds);
  return prepareCases(rawData);
}
  
const RelatedToSelector = (props) => {
  const [selectedForwardLinkedCases, setSelectedForwardLinkedCases] = useState([]);

  useEffect(() => {
    const useEffectLoadForwardLinkedCases = async () => {
      if (props.forwardLinkedCaseIds.length != 0) {
        let cases = await loadForwardLinkedCasesByIds(props.forwardLinkedCaseIds);
        setSelectedForwardLinkedCases(cases)
      }
    }
    useEffectLoadForwardLinkedCases();
  
  }, [setSelectedForwardLinkedCases])

  const handleChange = (inputValue) => {
    const newForwardLinkedCase = inputValue;
    if (!selectedForwardLinkedCases.map(x => x.value).includes(newForwardLinkedCase.value)) {
      if (props.currentCaseId != newForwardLinkedCase.value) {
        addForwardLinkedCase(newForwardLinkedCase)
      }
    }
  }

  const addForwardLinkedCase = (forwardLinkedCase)=> {
    // We're intentionally using a wrapper function (the, x => [...x, newValue])
    // Aee article below on why. Tldr: set to callback return value, not the pre return value.
    // https://medium.com/javascript-in-plain-english/how-to-add-to-an-array-in-react-state-3d08ddb2e1dc
    setSelectedForwardLinkedCases(selectedForwardLinkedCases => [...selectedForwardLinkedCases, forwardLinkedCase])
  }

  const removeForwardLinkedCaseById = (caseId) => {
    const newForwardLinkedCases = selectedForwardLinkedCases.filter((kase) => kase.value !== caseId);
    setSelectedForwardLinkedCases(newForwardLinkedCases)
  }

  const renderForwardLinkedCasesAsListItem = (kase) => {
    return <li key={kase.value}>
      <input type="hidden" name={props.selectName} value={kase.value}/>
      <a href={`/cases/${kase.value}`} target="_blank">{kase.label} </a>-<a href="#" onClick={() => removeForwardLinkedCaseById(kase.value)}> (Remove)</a>
    </li>;
  }
  
  return (
    <div className="form-group optional">
      <label className="col-sm-3 control-label select optional" htmlFor="case_forward_linked_cases_ids">Related Cases</label>
      <div className="col-sm-9">
        <ul style={{marginLeft: "-22px"}}>
          {selectedForwardLinkedCases.map(x => renderForwardLinkedCasesAsListItem(x))}
        </ul>
        <AsyncSelect
          value={null}
          placeholder={"Enter Case Name or Case Number"}
          cacheOptions
          defaultOptions
          isMulti={false}
          isClearable={true}
          loadOptions={loadOptions}
          onChange={handleChange}
          />
      </div>
    </div>
  )
}

RelatedToSelector.propTypes = {
  forwardLinkedCaseIds: PropTypes.arrayOf(PropTypes.number),
  currentCaseId: PropTypes.number,
  selectName: PropTypes.string,
}

RelatedToSelector.defaultProps = {
  selectName: 'case[forward_linked_case_ids][]'
};

export default RelatedToSelector;