import $ from 'jquery';
import 'select2';


window.addEventListener('DOMContentLoaded', () => {
    $("#defendant_judge_id").select2({
      placeholder: "Enter judge name",
      ajax: {
        url: "/judges/search_js",
        dataType: 'json',
        delay: 250,
        quietMillis: 100,
        data: function (params) {
          return {
            q: params.term,
            page: params.page
          };
        },
        processResults: function (data, params) {
          params.page = params.page || 1;
          return {
            results: data.items,
            pagination: {
              more: (params.page * 30) < data.items_count
            }
          };
        },
        cache: true
      },
      escapeMarkup: function (markup) { return markup; },
      minimumInputLength: 2,
      templateResult: function(judge) {
        if (judge == null || judge == undefined || judge.id == null) {
          return null
        } else {
          var fed_district_location = judge.fed_district_location || "(Fed District Unknown)";
  
          var markup = "" +
          "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
              "<b><span class='select2-result-repository__title'>" + judge.name + "</span></b>" +
                " "+fed_district_location+", " + judge.fed_district_number +
              "</div>" +
            "</div>" +
          "</div>";
  
          return markup
        }
      }
    });
  });
  