import $ from 'jquery';
import 'select2';

window.addEventListener('DOMContentLoaded', () => {

  function formatCase(kase) {
    if (kase == null || kase == undefined || kase.id == null) {
      return null
    } else {
      var markup = "<div class='select2-result-repository clearfix'>" +
      "<div class='select2-result-repository__meta'>" +
      "<b><span class='select2-result-repository__title'>" + kase.case_name + "</span></b>" +
      "&nbsp;";

      if(kase.case_number != undefined) {
        markup += "<span class='select2-result-repository__description'>" + kase.case_number + "</span>" +
        "&nbsp;"
      }

      markup += "<span class='select2-result-repository__watchers'>(website id = " + kase.id + ")</span>" +
      "</div>" +
      "</div>";

      return markup;
    }
  }

  function delete_linked_case(e) {
    e.preventDefault();

    var linking_id = $(e.target).data('linkingId');
    var id = "linked-case-" + linking_id;
    var case_id = htd.get_resource_id(level=1)
    var url = "/cases/" + case_id + "/linkings/" + linking_id + "/js_destroy";

    $.ajax({
      type: 'DELETE',
      url: url,
      dataType: 'json',
      success: function(data, text, jqxhr) {
        removeSelectedLiHTML(id);
      }
    });
  }

  function delete_case_tag(e) {
    e.preventDefault();

    var cases_tag_id = $(e.target).data('casesTagId');
    var id = "cases-tag-" + cases_tag_id;
    var case_id = htd.get_resource_id(level=1);
    var url = "/cases/" + case_id + "/cases_tags/" + cases_tag_id;

    $.ajax({
      type: 'DELETE',
      url: url,
      dataType: 'json',
      success: function(data, text, jqxhr) {
        removeSelectedLiHTML(id);
      }
    });
  }

  function removeSelectedLiHTML(id) {
    var selector_css = '#' + id;
    $(selector_css).remove();
  }

  function removeSelections() {
    $('.select2-selection__choice').remove()
  }

  function formatSelectedLiHTML(link_id, kase) {
    var html = "";
    html += "<li id='linked-case-" + link_id + "'>" +
    kase.case_name + ", " +
    kase.case_number +
    " (" + kase.id + ") " +
    "<a href='#' class='linked_case' data-linking-id=" + link_id  + ">Remove</a>";
    "</li>";

    return html;
  }

  $(document).on('click', '.linked_case', function(e){ delete_linked_case(e); });
  $(document).on('click', '.cases_tag',   function(e){ delete_case_tag(e); });

  $("#case_forward_linkings").select2({
    placeholder: "Enter case number or case numer",
    ajax: {
      url: "/cases/search",
      dataType: 'json',
      delay: 250,
      quietMillis: 100,
      data: function (params) {
        return {
          q: params.term,
          page: params.page
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;
        return {
          results: data.items,
          pagination: {
            more: (params.page * 30) < data.items_count
          }
        };
      },
      cache: true
    },
    escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
    minimumInputLength: 2,
    templateResult: formatCase
  });

  $('#case_forward_linkings').on("select2:select", function(e) {
    var case_id = htd.get_resource_id(level=1)

    var url = "/cases/" + case_id + "/linkings/js_create";
    var kase = e.params.data;
    var data = {reverse_linked_case_id: kase.id}

    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      data: data,
      success: function(data, text, jqxhr) {
        removeSelections();
        var link_id = data.id;
        var selected_case_html = formatSelectedLiHTML(link_id, kase);
        $('ul.forward_linked_cases').append(selected_case_html);
      }
    })
  });

  $('#case_tags').select2({
    placeholder: 'Enter tag name',
    ajax: {
      url: '/tags/search',
      dataType: 'json',
      delay: 250,
      quietMillis: 100,
      data: function (params) {
        return {
          q: params.term,
          page: params.page
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;
        return {
          results: data.items,
          pagination: {
            more: (params.page * 30) < data.items_count
          }
        };
      },
      cache: true
    },
    escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
    minimumInputLength: 1,
    templateResult: function(item) {

      if (item == null || item == undefined || item.id == null) {
        return null
      } else {
        var markup = "" +
        "<div class='select2-result-repository clearfix'>" +
          "<div class='select2-result-repository__meta'>" +
            "<b><span class='select2-result-repository__title'>" + item.name + "</span></b>"
            "</div>" +
          "</div>" +
        "</div>";

        return markup
      }
    },
  });

  $('#case_tags').on("select2:select", function(e) {
    var case_id = htd.get_resource_id(level=1)
    var url = "/cases/" + case_id + "/cases_tags";

    var data = {
      tag_id: e.params.data.id,
    }

    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      data: data,
      success: function(data, text, jqxhr) {

        var markup = "";
        markup += "<li id='cases-tag-" + data.id + "' data-cases-tag-id='" + data.id + "'>";
        markup += data.name;
        markup += " <a href='#' class='cases_tag' data-cases-tag-id='" + data.id + "'>Remove</a>";
        markup += "</li>";

        $('ul.tags').append(markup);
      }
    })

  });

});
